// ------------------------------------ //
// #DATE RANGE PICKER STYLE OVERRIDE
// ------------------------------------ //
.st-daterange {
    display: inline-block;
    position: relative;
    input {
        height: 50px;
        width: 240px;
        padding-right: 10px;
        text-align: right;
        color: white;
        background-color: $color-main;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        border: 1px solid transparent;
        background-color: $color-main;
    }
    &::before {
        content: '';
        position: absolute;
        background-image: url("/assets/icon/Management/calendar-alt-white.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
        padding: 10px;
        top: 15px;
        margin-left: 10px;
    }
    &.st-daterange--sm {
        input {
            height: 35px;
            width: 210px;
            font-size: 14px;
        }
        &::before {
            top:8px;
        }
    }
}

.md-drppicker {
    .btn {
        background-color: $color-main !important;
    }
    .ranges ul li button.active {
        background-color: $color-main !important;
    }
    td.active {
        background-color: $color-main !important;
    }
}
