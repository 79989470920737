// ------------------------------------ //
// #CARD
// ------------------------------------ //
.st-card {
    border: 1px solid #dddddd;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &.st-card-surface {
        margin-bottom: 35px;
        padding: $spacing $spacing * 1.5;
        .st-card-surface__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .st-card-surface__zone {
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
            }
        }
        n7-text-input {
            margin-right: 10px;
            width: 100px;
            &.is-warning {
                .n7-form-item__group {
                    border: 1px solid $error_color;
                    &:focus-within {
                        border: 1px solid $primary_color;
                    }
                    .n7-form-item__value {
                        text-align: right;
                    }
                }
            }
            .n7-form-item__group {
                .n7-form-item__value {
                    text-align: right;
                }
            }
        }
        .st-card-surface__row {
            padding: 20px 0;
            border-bottom: 1px solid $color-light-blue;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
    .st-card__label {
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
    }
    &.st-card--project {
        max-width: 500px;
        margin: 0 auto 20px;
        padding: 0 20px 20px;
        .form-item .item-label {
            text-align: left;
        }
        .st-project-text {
            height: 50px;
            display: flex;
            justify-content: space-between;
            padding: 8px 16px 8px 8px;
            font-size: 16px;
            .item-input-group__append {
                padding-left: 10px;
                color: #9b9b9b;
                font-size: 18px;
            }
        }
    }
}
