// ------------------------------------ //
// #for work steps on management pages
// ------------------------------------ //

/* ------------------------------------ *\
# Desktop 
\* ------------------------------------ */
.st-card-work__steps {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-items: end;
    app-st-work-step {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 0;
        width: inherit;
        height: 100%;
        &::after {
            display: none;
        }
        .st-step {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .st-step__title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            min-height: 46px;
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            color: $color-main;
            text-align: center;
            background-color: #aedeff;
            border-radius: 3px;
            -webkit-clip-path: (
                95% 0%,
                100% 50%,
                95% 100%,
                0% 100%,
                5% 50%,
                0% 0%
            );
            clip-path: polygon(
                95% 0%,
                100% 50%,
                95% 100%,
                0% 100%,
                5% 50%,
                0% 0%
            );
            .st-step__subtitle {
                margin-bottom: 5px;
                font-size: 10px;
                line-height: 11px;
                color: $color-main;
                text-align: center;
            }
        }
        &:first-child {
            .st-step__title {
                -webkit-clip-path: (
                    95% 0%,
                    100% 50%,
                    95% 100%,
                    0% 100%,
                    0% 50%,
                    0% 0%
                );
                clip-path: polygon(
                    95% 0%,
                    100% 50%,
                    95% 100%,
                    0% 100%,
                    0% 50%,
                    0% 0%
                );
            }
        }
        &:last-child {
            .st-step__title {
                -webkit-clip-path: (
                    100% 0,
                    100% 50%,
                    100% 100%,
                    0% 100%,
                    5% 50%,
                    0% 0%
                );
                clip-path: polygon(
                    100% 0,
                    100% 50%,
                    100% 100%,
                    0% 100%,
                    5% 50%,
                    0% 0%
                );
            }
        }
        .is-unavailable {
            .st-step__title,
            .st-step__subtitle {
                background-color: $color-light-blue;
                color: $gray500;
            }
        }
        .is-active {
            .st-step__title,
            .st-step__subtitle {
                background-color: $color-main;
                color: white;
            }
        }
        .st-step__list {
            position: relative;
            margin-bottom: auto;
            height: 100%;
            .st-step__list-item {
                font-size: 12px;
                line-height: 20px;
                padding-left: 10px;
                padding-right: 5px;
                &:first-child {
                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 5px;
                        position: absolute;
                        left: -5px;
                        top: 4px;
                        width: 10px;
                        height: 10px;
                        background-color: $color-main;
                        border-radius: 50%;
                    }
                    &::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: 4px;
                        left: 0;
                        width: 1px;
                        height: calc(100% - 14px);
                        background-color: $color-main;
                    }
                }
            }
        }
    }
}
/* ------------------------------------ *\
  #MEDIA-QUERIES
\* ------------------------------------ */
// smaller than md:768px
@media all and (max-width: 768px) {
    .st-card-work__steps {
        display: flex;
        flex-direction: column;
        height: inherit;
        app-st-work-step {
            position: relative;
            margin-bottom: 15px;
            padding-bottom: 15px;
            width: 100%;
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                bottom: 0;
                right: 0;
                width: calc(100% - 35px);
                border-bottom: 1px solid $color-light-blue;
            }
            .st-step {
                display: block;
                height: inherit;
            }
            .st-step__title {
                display: block;
                position: relative;
                padding-left: 35px;
                min-height: 21px;
                color: inherit;
                text-align: left;
                background-color: white;
                -webkit-clip-path: none;
                clip-path: none;
                .st-step__subtitle {
                    margin-bottom: 0px;
                    color: inherit;
                    text-align: left;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    left: 0;
                    width: 26px;
                    height: 15px;
                    background-color: #aedeff;
                    -webkit-clip-path: polygon(
                        0% 0%,
                        75% 0%,
                        100% 50%,
                        75% 100%,
                        0% 100%
                    );
                    clip-path: polygon(
                        0% 0%,
                        75% 0%,
                        100% 50%,
                        75% 100%,
                        0% 100%
                    );
                }
            }
            &:first-child {
                .st-step__title {
                    -webkit-clip-path: none;
                    clip-path: none;
                }
            }
            &:last-child {
                .st-step__title {
                    -webkit-clip-path: none;
                    clip-path: none;
                }
            }
            .is-unavailable {
                .st-step__title {
                    background-color: inherit;
                    color: $gray500;
                    &::before {
                        background-color: $color-light-blue;
                    }
                    .st-step__subtitle {
                        background-color: inherit;
                        color: $gray500;
                    }
                }
            }
            .is-active {
                .st-step__title {
                    background-color: inherit;
                    color: inherit;
                    &::before {
                        background-color: $color-main;
                    }
                    .st-step__subtitle {
                        background-color: inherit;
                        color: inherit;
                    }
                }
            }
            .st-step__list {
                position: relative;
                margin-left: 35px;
                height: inherit;
                &::before {
                    display: none;
                }
                .st-step__list-item {
                    padding-left: 0;
                    padding-right: 5px;
                    &:first-child {
                        &::before {
                            display: none;
                        }
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
