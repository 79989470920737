// Raleway
@font-face {
    font-family: 'Raleway';
    src: url('../assets/fonts/Raleway/Raleway-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../assets/fonts/Raleway/Raleway-Black.ttf');
    font-weight: bold;
    font-style: normal;
}
