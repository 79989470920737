// ------------------------------------ //
// #BUTTONS
// ------------------------------------ //
button {
    padding: 0;
    &:focus {
        outline: 0;
    }
}
.st-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: $color-main;
    border: 1px solid $color-main;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.32px;
    text-align: center;
    cursor: pointer;
    height: 35px;
    vertical-align: top;
    text-align: center;
    text-decoration: none;
    /* Icon */
    &__icon-left {
        ion-icon {
            color: inherit;
            font-size: 20px;
            padding-right: 10px;
            margin-left: -5px;
        }
    }
    &__icon-right {
        ion-icon {
            color: inherit;
            font-size: 20px;
            padding-left: 10px;
            margin-right: -5px;
        }
    }
    &__select-icon-left {
        &::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background: url("../assets/icon/Management/calendar-alt-white.svg")
                no-repeat;
        }
    }
    &--lg {
        height: 50px;
        font-size: 18px;
    }
    &:hover {
        background-color: darken($color-main, 10%);
    }
}
.st-btn-secondary {
    border: 2px solid $gray-light;
    color: $color-main;
    background-color: transparent;
    &:hover {
        background-color: $blue100;
        border: 2px solid $blue100;
    }
    &-thin {
        border: 1px solid $gray-light;
        &:hover {
            border: 1px solid $blue100;
        }
    }
}
.st-btn-transparent {
    background-color: transparent;
    &:hover {
        color: $color-main;
        text-decoration: none;
    }
}
.st-btn-full {
    width: 100%;
}
.st-btn-binary {
    display: flex;
    align-items: center;
    text-align: center;
    border-top: 1px solid $color-light-blue;
    button {
        padding-top: 22px;
        padding-bottom: 22px;
        flex-basis: 50%;
        flex-grow: 1;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        &.is-disabled {
            //opacity: 0.4;
            background-color: #a7c6f2;
            pointer-events: none;
        }
    }
    &__left {
        color: $color-main;
        background-color: $white;
        &:hover {
            background-color: $blue100;
        }
    }
    &__right {
        color: $white;
        background-color: $color-main;
        &:hover {
            background-color: darken($color-main, 10%);
        }
    }
}
/* Ionic buttons style */
/* override common ionic buttons */
ion-button {
    /* Background of the button on hover */
    /* #074a92: darken($color-main, 10%); */
    text-transform: none;
    margin: 0;
    font-size: 14px;
    --box-shadow: none;
}
/* button primary */
ion-button.st-btn {
    padding: 0;
    --border-radius: 5px;
    --background: $color-main;
    --background-hover: #074a92;
}
/* button secondary */
ion-button.st-btn-secondary {
    border: none; //remove border from normal st-btn-secondary class
    --color: $color-main;
    --background: #ffffff;
    --border-width: 2px;
    --border-style: solid;
    --border-color: #dbdbdb;
    --background-hover: #bee7ff;
    --color-hover: $color-main;
    --color-activated: #bee7ff;
    &:hover {
        --border-color: #bee7ff;
        border: none; //remove border from normal st-btn-secondary class
    }
    &.st-btn-secondary-thin {
        --border-width: 1px;
    }
}

.ionic_datepicker_modal_content {
    --color-hover: #ffffff;
}