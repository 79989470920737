/**
 * FORM ELEMENTS
 *
 * Form classes and styles
*/
// ------------------------------------ //
// #FORM ELEMENTS
// ------------------------------------ //
/* Input style added focus color*/
.form-item {
    .item-input-group {
        &:focus-within {
            border: 2px solid $color-main;
        }
    }
}
/* Searchbar style */
.st-form-search {
    /* Placeholder color */
    --placeholder-color: #878f92;
    --placeholder-opacity: 1;
    padding: 0 !important;
    /* overide ion-search style */
    .searchbar-input-container {
        height: 50px;
    }
    .searchbar-input {
        padding-top: 11px;
        padding-bottom: 11px;
        box-shadow: none;
        border: 2px solid $gray-light;
        border-radius: 5px;
        font-size: 16px;
        line-height: 24px;
        background-color: white;
    }
    &.searchbar-has-focus {
        .searchbar-input {
            border: 2px solid $color-main;
        }
    }
    .searchbar-search-icon {
        top: 14px;
        width: 23px;
        height: 23px;
        opacity: 0.5;
    }
}
/* form textarea style */
ion-textarea {
    border-radius: 5px;
    &.st-form-textarea {
        --border-radius: 5px;
        .native-textarea {
            padding: 10px 8px;
            font-size: 16px;
            line-height: 24px;
            min-height: 120px;
            border: 1px solid $color-light-blue;
            &:focus {
                border: 1px solid $color-main;
            }
        }
        &.is-warning {
            .native-textarea {
                border: 1px solid $color-danger;
                &:focus {
                    border: 1px solid $color-main;
                }
            }
        }
        &.st-card-surface__textarea {
            margin-top: 0;
            .native-textarea {
                min-height: 50px;
            }
        }
    }
}
/* simple checkbox style */
.st-checkbox {
    /* Hide the browser’s default checkbox button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    display: flex;
    align-items: center;
    padding-right: 5px;
    ion-icon {
        margin-right: 5px;
        font-size: 24px;
        color: $color-light-blue;
        &.st-checkbox__icon--true {
            color: $color-main;
        }
    }
    &__label {
        margin-bottom: 2px;
        font-size: 16px;
    }
}
/* checkbox list style: tertiary, in page Surface report*/
.st-checkbox-list-tertiary {
    .n7-form-item__group {
        display: none;
    }
    .n7-form-list__options {
        padding: 0;
    }
    .n7-form-list {
        border: none;
    }
    .n7-form-list__option {
        position: relative;
        min-height: 60px;
        .option-label {
            margin-left: 20px;
            font-size: 16px;
            line-height: 21px;
            font-weight: 600;
            letter-spacing: inherit;
        }
        .option-checkbox {
            position: absolute;
            top: 3px;
            ion-icon[name="checkbox"] {
                color: $color-main;
            }
        }
    }
    hr {
        border-bottom: 1px solid #dddddd;
    }
}
/* checkbox list style: horizontal, in page resource detail*/
.st-checkbox-list-horizontal {
    .n7-form-item__group {
        display: none;
    }
    .n7-form-list__options {
        display: flex;
        padding: 0;
    }
    .n7-form-list {
        border: none;
    }
    .n7-form-list__option {
        position: relative;
        min-height: 48px;
        border-top: none;
        padding: 0 5px 0 0;
        .option-label {
            margin-left: 10px;
            font-size: 16px;
            line-height: 21px;
            font-weight: normal;
            letter-spacing: inherit;
        }
        .option-checkbox {
            position: absolute;
            top: -3px;
            ion-icon[name="checkbox"] {
                color: $color-main;
            }
        }
    }
    hr {
        display: none;
    }
}
/* Select date period popover */
.st-period-select-popover {
    /* hide ion select radio */
    ion-select-popover ion-list ion-item {
        --border-width: 0;
        --inner-border-width: 0;
    }
    ion-select-popover ion-list ion-item ion-radio {
        --color: transparent;
        --color-checked: transparent;
    }
}
