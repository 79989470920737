@use 'sass:math';

/**
 * UTILITY
 *
 * Utility classes and styles
 * Helper classes
*/

/* Container */
.container,
.container-sm,
.container-md,
.container-lg {
    width: 100%;
    margin: 0 auto 20px;
    padding: 0 16px;
}
.container-sm {
    max-width: 400px;
}
.container-md {
    max-width: 900px;
}
.container {
    max-width: 1140px;
}
.container-lg {
    max-width: 1400px;
}
.p-relative {
    position: relative;
}
.no-border {
    border: none !important;
}
/* ------------------------------------ *\
#TYPOGRAPHY
\* ------------------------------------ */
/* Typography */
.st-h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
}
.st-h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
}
.st-h3 {
    font-weight: bold;
    font-size: 21px;
    line-height: 40px;
}
.st-h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}
.st-h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}
.st-h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.st-p1 {
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
}
.st-p2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
}
.st-p3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
}
.st-p4 {
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
}
.st-form-label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 20px;
    &__group {
        display: flex;
        align-items: center;
        button {
            background-color: transparent;
        }
        ion-icon {
            padding-left: 5px;
            color: $gray500;
            &:hover {
                cursor: pointer;
                color: $gray800;
            }
        }
    }
}
.st-text-center {
    text-align: center;
}
.st-text-light {
    color: $gray500;
}
.st-link {
    text-decoration: none;
    &:active {
        color: $blue100;
    }
}
/* layout elements */
.st-page-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 35px 0;
    .st-page-title__group {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        ion-icon {
            width: 20px;
            height: 20px;
        }
    }
}
.st-page-subtitle {
    font-size: 14px;
    line-height: 21px;
    color: $gray500;
}
.st-action-top {
    max-width: 900px;
    margin: 0 auto;
    padding: 10px;
}
/* ------------------------------------ *\
#FLEX MODO
\* ------------------------------------ */
/* Flex modo */
.d-flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}
.flex-column {
    flex-direction: column;
}
.align-items-center {
    align-items: center;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-space-between {
    justify-content: space-between;
}
.h-100 {
    height: 100%;
}
/* ------------------------------------ *\
#SPACING-CLASS
\* ------------------------------------ */
/* Spacing */
/* 10px margin-top - system base spacing 10px */
.st-margin-top {
    margin-top: $spacing;
}

/* 10px margin-bottom - system base spacing 10px */
.st-margin-bottom {
    margin-bottom: $spacing;
}

/* 30px margin-bottom - system base spacing 10px */
.st-margin-bottom-l {
    margin-bottom: $spacing * 3;
}

/* 40px margin-bottom - system base spacing 10px */
.st-margin-bottom-xl {
    margin-bottom: $spacing * 4;
}

/* 50px margin-bottom - system base spacing 10px */
.st-margin-bottom-xxl {
    margin-bottom: $spacing * 5;
}

/* 60px margin-bottom - system base spacing 10px */
.st-margin-bottom-xxxl {
    margin-bottom: $spacing * 6;
}
/* 5px margin-left - system base spacing 10px */
.st-ml-5 {
    margin-left:  math.div($spacing, 2);
}
/* 10px padding - system base spacing 10px */
.st-padding {
    padding: $spacing;
}
/* ------------------------------------ *\
#PULSE-RING
\* ------------------------------------ */
.pulse-ring {
    display: none;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    position: absolute;
    -webkit-animation: animation-pulse 2s ease-out;
    animation: animation-pulse 2s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0;
    border-width: 3px;
    border-style: solid;
    border-color: #e4e6ef;
}
@keyframes animation-pulse {
    // 0% {
    //     -webkit-transform: scale(0.1, 0.1);
    //     opacity: 0;
    // }
    // 60% {
    //     -webkit-transform: scale(0.1, 0.1);
    //     opacity: 0;
    // }
    // 65% {
    //     opacity: 1;
    // }
    // 100% {
    //     -webkit-transform: scale(1.2, 1.2);
    //     opacity: 0;
    // }
    0% {
        background-color: #fff;
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0.5;
    }

    30% {
        background-color: #fff;
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0.5;
    }

    100% {
        background-color: $color-danger;
        -webkit-transform: scale(1.1, 1.1);
        opacity: 0;
    }
}
/* Modal style */
ion-modal ion-toolbar ion-button {
    padding: 0 !important;
}
.st-modal {
    .modal-wrapper {
        border-radius: 5px;
    }
    &.auto-height {
        &.bottom {
            align-items: flex-end;
        }

        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                max-height: 80vh;
                overflow: auto;
                padding: 10px;
            }
        }
    }
    &.st-modal-center {
        padding: 20px;
        .modal-wrapper {
            max-width: 500px;
        }
    }
    &.st-modal-full {
        .modal-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
}

/* Alert style */
.st-alert {
    .alert-wrapper {
        .alert-message {
            color: $black;
        }
    }
    .alert-button-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        border-top: 1px solid $color-light-blue;
    }
    .alert-button {
        flex-grow: 1;
        padding-top: 14px;
        padding-bottom: 14px;
        margin: 0;
        .alert-button-inner.sc-ion-alert-md {
            justify-content: center;
            text-transform: capitalize;
        }
    }
    button {
        &.st-alert__btn-left {
            color: $color-main;
            background-color: $white;
            &:hover {
                background-color: rgba(66, 66, 66, 0.08);
            }
        }
        &.st-alert__btn-right {
            color: $white;
            background-color: $color-main;
            &:hover {
                background-color: darken($color-main, 10%);
            }
        }
    }
}
/* Toast style */
.st-toast {
    --max-width: 100%;
    --min-height: 50px;
    --ion-safe-area-top: 0;
    --border-radius: 0;
    --start: 0;
    --end: 0;
    --border-width: 5px;
    --border-style: solid;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    &--success {
        --background: #0b8f1f;
        --border-color: #0b8f1f;
    }
    &--danger {
        --background: #cc0019;
        --border-color: #cc0019;
    }
    &--warning {
        --background: #f5a623;
        --border-color: #f5a623;
    }
}
/* Filter elements */
.st-filter-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $color-light-blue;
    border-bottom: 1px solid $color-light-blue;
    padding-top: 10px;
    padding-bottom: 10px;
    label {
        margin: 5px $spacing * 2 5px 5px;
        text-transform: uppercase;
    }
    &__item {
        margin: 0 5px;
    }
    @media (min-width: $sm) {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.st-filter-result {
    padding: 0 10px;
    font-size: 16px;
    line-height: 24px;
    color: $gray500;
    .strong {
        font-weight: bold;
    }
}

/* Popover Action sheet style */
.st-popover-actions {
    .st-popover {
        padding: 10px 20px;
        cursor: pointer;
    }
    &__option {
        padding: 8px 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        border-bottom: 1px solid $color-light-blue;
    }
    &__cancel {
        padding: 8px 0;
        font-size: 14px;
        line-height: 34px;
    }
}

/* ------------------------------------ *\
  #DOTS
\* ------------------------------------ */
.st-dot {
    display: inline-block;
    width: $spacing;
    height: $spacing;
    border-radius: 50%;
    background-color: $color-light-blue;

    /* Blue background */
    &--blue {
        background-color: $color-main;
    }

    /* Light blue background */
    &--light-blue {
        background-color: #aedeff;
    }
    /* Light blue background */
    &--dark-blue {
        background-color: #003fa0;
    }
}

/* ------------------------------------ *\
  #TO KEEP ELEMENT AS FOOTER IN THE SAFE AREA
\* ------------------------------------ */
.st-safe-footer {
    // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        --safe-area-inset-top: constant(safe-area-inset-top);
        --safe-area-inset-bottom: constant(safe-area-inset-bottom);
        padding-bottom: calc(16px + var(--safe-area-inset-bottom)) !important;
    }

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        --safe-area-inset-top: env(safe-area-inset-top, 35px);
        --safe-area-inset-bottom: env(safe-area-inset-bottom, 35px);
        padding-bottom: calc(16px + var(--safe-area-inset-bottom)) !important;
    }
}