// ------------------------------------ //
// #HEADER
// ------------------------------------ //

.header-md:after {
    // Remove native shadow ionic
    opacity: 0;
}
ion-header.st-header {
    ion-toolbar {
        border-bottom: 1px solid #ebebeb;
    }
    .link {
        cursor: pointer;
    }
    .breadcrumbs {
        color: #666;
        font-size: 16px;
        display: block;
    }
    .breadcrumbs {
        font-weight: 500;
        &.current {
            font-weight: bold;
        }
    }
    ion-button {
        position: relative;
        overflow: visible !important;
        color: #cccccc;
        &.hidden {
            opacity: 0;
        }
        // ion-icon[slot="start"] {
        //     margin-right: -4px;
        // }
        ion-label {
            text-transform: capitalize;
            letter-spacing: 0;
        }
    }
    .buttons-last-slot {
        ion-button {
            color: $color-main;
        }
    }
    .st-btn-label {
        @media (max-width: 768px) {
            display: none;
        }
    }

    ion-badge {
        width: 10px;
        height: 10px;
        margin-top: -10px;
        margin-left: -10px;
        padding: 0;
        border-radius: 5px;
    }
    .st-header-cta {
        ion-label {
            margin-left: 10px;
        }
    }
}
