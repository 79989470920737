// ------------------------------------ //
// #DATATABLE STYLES
// ------------------------------------ //

/* Import ngx-datatable datatable styles */
// @import "@swimlane/ngx-datatable/index.css";
// @import "@swimlane/ngx-datatable/themes/material.scss";
// @import "@swimlane/ngx-datatable/assets/icons.css";

/* override ngx-datatable style */
.ngx-datatable.material {
    box-shadow: none;
}
.datatable-body-row {
    border-bottom: 1px solid $color-light-blue;
}
.ngx-datatable
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.ngx-datatable.material .datatable-footer {
    border-top: none;
}
.ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    padding: 10px;
}
/* Datatable style */
.datatable-icon-up:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    background-repeat: no-repeat;
    background-image: url("/assets/icon/Management/arrow-up-regular.svg");
}
.datatable-icon-down:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    background-repeat: no-repeat;
    background-image: url("/assets/icon/Management/arrow-down-regular.svg");
}

.st-datatable {
    // margin: 0 15px;
    a {
        text-decoration: none;
    }
    &__data {
        display: flex;
        align-items: center;
        min-height: 40px;
        font-size: 12px;
        line-height: 20px;
        white-space: pre-wrap;
        &.is-warning {
            color: $color-danger;
            // cursor: pointer;
            // background-image: url("/assets/icon/Management/ellipsis-v-regular.svg");
            // background-repeat: no-repeat;
            // background-position: right;
            // background-size: 5%;
        }
    }
    &__data--centered {
        justify-content: center;
        padding-right: 20px;
    }
    &__data-icon {
        font-size: 24px;
        color: $color-light-blue;
        &:active {
            color: $color-main;
        }
        &:hover {
            color: $color-main;
        }
    }
    .st-resource-group {
        display: flex;
        align-items: center;
        .st-datatable__checkbox {
            padding-right: 10px;
            font-size: 24px;
            color: $color-light-blue;
            &.is-checked {
                color: $color-main;
            }
        }
    }
    &-anomalies {
        position: relative;
        .st-datatable-anomalies__checkall {
            position: absolute;
            z-index: 1;
            left: 11px;
            top: 10px;
            .ngx-datatable.fixed-header
                .datatable-header
                .datatable-header-inner
                .datatable-header-cell {
                &:first-child {
                    padding-left: 36px;
                }
            }
        }
    }
}
.st-datatable-anomalies__data {
    .datatable-header datatable-header-cell {
        &:first-child {
            .datatable-header-cell-label {
                padding-left: 36px;
            }
        }
    }
}
