// @import "variables";

$primary_color: var(--ion-color-primary);
$tertiary_color: #EEEEEE;
$tertiary_color_80: rgba(238, 238, 238, 0.8);
$error_color: #D0021B;
$remark_color: #FFAB00;
$light_color: #FFFFFF;
$dark_color: #000000;

$primary_text_color: #000000;
$secondary_text_color: #4A4A4A;
$tertiary_text_color: #9B9B9B;

$border_color: #DDDDDD;

$bg_color_light: #FFFFFF;
$bg_color_gray: #F5F5F5;
$bg_color_dark: #000000;

.n7-label {
    display: block;
    margin: 32px 0 8px;
    color: $primary_text_color;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
}

.n7-message {
    margin: 8px 0 0;
    color: $secondary_text_color;
    font-size: 10px;
    line-height: 1.2;

    > p {
        margin: 0;
    }
}

.n7-hint {
    color: $secondary_text_color;

    > ion-icon {
        vertical-align: middle;
    }

    &.warning {
        > ion-icon {
            color: $error_color;
        }
    }

    &.error {
        color: $error_color;

        > ion-icon {
            color: $error_color;
        }
    }
}

.n7-error {
    color: $error_color;
}

.n7-form-item {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
    color: $primary_text_color;
    font-size: 16px;
    line-height: 1.2;

    .n7-form-item__value {
        border: none;
        line-height: 30px;
        outline: none;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $tertiary_text_color;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $tertiary_text_color;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $tertiary_text_color;
    }
}

.n7-form-list {
    display: none;
    position: absolute;
    top: 47px;
    left: 0;
    right: 0;
    border: 1px solid $border_color;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: $bg_color_light;
    z-index: 2;
}

.n7-form-list__options {
    list-style: none;

    // min-height: 46px;
    max-height: 250px;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    .modal-form-large & {
        max-height: 220px;
    }
}

.n7-form-list__prefix,
.n7-form-list__suffix,
.n7-form-list__option {
    padding: 8px 16px;
    border: none;
    line-height: 30px;
    outline: none;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.n7-form-list__prefix {
    border-bottom: 1px solid $border_color;
}

.n7-form-list__suffix {
    border-top: 1px solid $border_color;
}

.n7-form-list__option {
    &.no-items {
        display: none;
        color: $tertiary_color;

        &:only-child {
            display: block;
        }
    }
}

.n7-form-list__button {
    color: $primary_color;
    font-weight: bold;

    > ion-icon {
        font-size: 24px;
        vertical-align: sub;
    }
}

.n7-form-list__option {
    border-top: 1px solid $border_color;
    line-height: 30px;
    outline: none;
    text-align: center;

    &:first-child {
        border: none;
    }

    &.selected {
        color: $primary_color;
    }
}

.n7-form-container {
    box-sizing: border-box;
    padding: 8px;
    border: 2px solid $border_color;
    border-radius: 5px;

    > *:not(:first-child) {
        margin: 20px 0 0;
    }
}

n7-button {
    display: block;

    &.inline {
        display: inline-block;
    }

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item {
        cursor: pointer;

        &.disabled {
            cursor: initial;
            pointer-events: none;
        }
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        min-height: 38px;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;
        cursor: pointer;

        .n7-form-item__value {
            flex-shrink: 0;
            align-self: center;

            // color: $primary_color;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;
            width: 24px;
            flex-basis: 24px;
            flex-grow: 0;
            flex-shrink: 0;

            > ion-icon {
                position: absolute;
                top: -12px;
                font-size: 24px;
                vertical-align: middle;
            }
        }

        .n7-form-item__prefix {
            > ion-icon {
                left: -8px;
            }
        }

        .n7-form-item__suffix {
            > ion-icon {
                right: -8px;
            }
        }
    }

    .theme-primary {
        .n7-form-item__group {
            border-color: $primary_color;
            color: $light_color;
            background-color: $primary_color;
        }
    }

    .theme-secondary {
        .n7-form-item__group {
            border-color: $border_color;
            color: $primary_color;
            background-color: $light_color;
        }
    }

    .theme-tertiary {
        .n7-form-item__group {
            border-color: $bg_color_gray;
            color: $primary_color;
            background-color: $bg_color_gray;
        }
    }

    .theme-link {
        .n7-form-item__group {
            border-color: transparent;
            color: $primary_color;
            background-color: transparent;
        }
    }

    .theme-delete {
        .n7-form-item__group {
            border-color: $border_color;
            color: $error_color;
            background-color: $light_color;
        }
    }

    .theme-remark {
        .n7-form-item__group {
            border-color: $remark_color;
            color: $light_color;
            background-color: $remark_color;
        }
    }

    .theme-applog {
        .n7-form-item__group {
            border-color: $bg_color_gray;

            // color: $primary_color;
            background-color: $bg_color_gray;
        }
    }

    .theme-slim {
        .n7-form-item__value {
            line-height: 19px;
        }
    }

    .theme-fluid {
        .n7-form-item__group {
            .n7-form-item__value {
                flex-grow: 100;
            }

            .n7-form-item__prefix,
            .n7-form-item__suffix {
                flex-grow: 1;
            }

            .n7-form-item__prefix {
                > ion-icon {
                    left: 0;
                }
            }

            .n7-form-item__suffix {
                > ion-icon {
                    right: 0;
                }
            }
        }
    }

    .theme-popover {
        .n7-form-item__group {
            justify-content: flex-start;
            padding: 16px 24px;
            border-color: transparent;

            // border-bottom: 1px solid $border_color;
            // border-radius: 0;
            color: $primary_color;
            background-color: $light_color;
        }

        // &:last-child {
        //     .n7-form-item__group {
        //         border-color: transparent;
        //     }    
        // }
   
    }

    .theme-popover-delete {
        .n7-form-item__group {
            justify-content: flex-start;
            padding: 16px 24px;
            border-color: transparent;

            // border-bottom: 1px solid $border_color;
            // border-radius: 0;
            color: $error_color;
            background-color: $light_color;
        }

        // &:last-child {
        //     .n7-form-item__group {
        //         border-color: transparent;
        //     }    
        // }
   
    }

    &.icon-only {
        .n7-form-item__group {
            .n7-form-item__value {
                display: none;
            }

            .n7-form-item__suffix {
                width: 8px;

                > ion-icon {
                    right: 0;
                }
            }
        }
    }

    ion-toolbar.disabled & {
        .n7-form-item {
            &.disabled {
                opacity: 1;
            }

            .n7-form-item__group {
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}

n7-text-input {
    display: block;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 0;
            flex-basis: 0;
            flex-grow: 1;
            align-self: center;
        }

        .n7-form-item__prefix {
            display: block;
            padding-right: 8px;
            margin-right: 8px;
        }

        .n7-form-item__suffix {
            &.button {
                flex-grow: 1;
                text-align: center;
                color: $primary_color;
                cursor: pointer;
            }

            &.disabled {
                pointer-events: none;
            }
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;
            line-height: 30px;
            white-space: nowrap;

            // overflow: hidden;
            text-overflow: ellipsis;

            &.button {
                overflow: hidden;
            }

            > ion-icon {
                position: absolute;
                top: -24px;
                right: -18px;
                color: $tertiary_text_color;
                font-size: 48px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    // .n7-form-item__value[type=number] {
    //     text-align: right;
    // }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    &.large {
        .n7-form-item__group {
            padding: 16px;
            font-size: 22px;

            .n7-form-item__prefix {
                > ion-icon {
                    left: -10px;
                }
            }

            .n7-form-item__suffix {
                > ion-icon {
                    right: -10px;
                }
            }
        }
    }
}

.n7-multiple-input {
    n7-text-input {
        &:not(:first-child) {
            margin: 0;

            > .n7-label {
                display: none;
            }

            .n7-form-item__group {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &:not(:last-child) {
            > .n7-message {
                display: none;
            }

            .n7-form-item__group {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        & + n7-text-input {
            .n7-form-item__group {
                margin-top: -1px;
                border-top: 1px solid transparent;
                background-color: transparent;

                &:focus-within {
                    border: 1px solid $primary_color;
                }
            }
        }
    }

    &.one-row {
        display: flex;
        align-items: flex-end;

        n7-text-input {
            flex-grow: 1;

            &:not(:first-child) {
                margin: 0;

                > .n7-label {
                    display: none;
                }

                .n7-form-item__group {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &:not(:last-child) {
                > .n7-message {
                    display: none;
                }

                .n7-form-item__group {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &:first-child {
                .n7-form-item__group {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 0;
                }
            }

            &:last-child {
                .n7-form-item__group {
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 5px;
                }
            }

            & + n7-text-input {
                .n7-form-item__group {
                    margin-top: 0px;
                    margin-left: -1px;
                    border-top: 1px solid $border_color;
                    border-left: 1px solid transparent;
                    background-color: transparent;

                    &:focus-within {
                        border: 1px solid $primary_color;
                    }
                }
            }
        }
    }
}

n7-text-picker {
    display: block;

    &:first-child {
        margin: 0 0 0;
    }

    &:last-child {
        margin: 0 0 210px;
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 0;
            flex-basis: 0;
            flex-grow: 1;
            align-self: center;
            color: $primary_color;
        }

        .n7-form-item__prefix {
            display: block;
            padding-right: 8px;
            margin-right: 8px;
        }

        .n7-form-item__suffix {
            &.button {
                flex-grow: 1;
                text-align: center;
                color: $primary_color;
                cursor: pointer;
            }

            &.disabled {
                pointer-events: none;
            }
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;
            line-height: 30px;
            white-space: nowrap;

            // overflow: hidden;
            text-overflow: ellipsis;

            &.button {
                overflow: hidden;
            }

            > ion-icon {
                position: absolute;
                top: -12px;
                right: -4px;
                color: $primary_color;
                font-size: 24px;
                vertical-align: middle;
                cursor: pointer;

                &.large {
                    top: -18px;
                    right: -10px;
                    font-size: 36px;
                    color: $tertiary_text_color;
                }
            }
        }
    }

    // .n7-form-item__value[type=number] {
    //     text-align: right;
    // }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;

            .n7-form-item__value {
                color: $primary_text_color;
            }
        }
    }

    .n7-form-list__option {
        border: none;
        text-align: left;
    }

    .n7-form-list__button {
        text-align: left;
    }

    .is-open {
        .n7-form-item__group {
            border-color: $primary_color;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            z-index: 99;

            .n7-form-item__value {
                color: $primary_text_color;
            }
        }

        .n7-form-list {
            display: block;
            box-shadow: 0 20px 40px -20px rgba(186, 186, 186, 0.8);
            border-bottom-color: $primary_color;
            border-left-color: $primary_color;
            border-right-color: $primary_color;
            z-index: 99;
        }

        &.with-backdrop:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba($color: $bg_color_dark, $alpha: 0.3);
            z-index: 98;
            cursor: default;
        }
    }
}

n7-drop-down {
    display: block;

    // margin: 8px 0 0;
    cursor: pointer;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item {
        &.disabled {
            pointer-events: none;
        }
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 100px;
            flex-basis: 100px;
            flex-grow: 1;
            flex-shrink: 0;
            align-self: center;
            color: $primary_color;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;

            > ion-icon {
                position: absolute;
                top: -12px;
                right: -4px;
                color: $primary_color;
                font-size: 24px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    .n7-form-list {
        display: none;
        position: absolute;
        top: 47px;
        left: 0;
        right: 0;
        border: 1px solid $border_color;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $bg_color_light;
        z-index: 2;
    }

    .n7-form-list__options {
        list-style: none;
        max-height: 250px;
        margin: 0;
        padding: 0;
        overflow-y: auto;
    }

    .n7-form-list__prefix,
    .n7-form-list__suffix,
    .n7-form-list__option {
        padding: 8px 16px;
        border: none;
        line-height: 30px;
        outline: none;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .n7-form-list__prefix {
        border-bottom: 1px solid $border_color;
    }

    .n7-form-list__suffix {
        border-top: 1px solid $border_color;
    }

    .n7-form-list__button {
        color: $primary_color;
        font-weight: bold;

        > ion-icon {
            font-size: 24px;
            vertical-align: sub;
        }
    }

    .n7-form-list__option {
        border-top: 1px solid $border_color;
        line-height: 30px;
        outline: none;
        text-align: center;

        &:first-child {
            border: none;
        }

        &.selected {
            color: $primary_color;
        }
    }

    .is-open {
        .n7-form-item__group {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            z-index: 99;

            .n7-form-item__value {
                color: $primary_text_color;
            }
        }

        .n7-form-list {
            display: block;
            box-shadow: 0 20px 40px -20px rgba(186, 186, 186, 0.8);
            z-index: 99;
        }

        &.with-backdrop:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba($color: $bg_color_dark, $alpha: 0.3);
            z-index: 98;
            cursor: default;
        }
    }
}

n7-checkbox-list,
app-st-filter-checkbox-list {
    display: block;

    // margin: 8px 0 0;
    cursor: pointer;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item {
        &.disabled {
            pointer-events: none;
        }
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 100px;
            flex-basis: 100px;
            flex-grow: 1;
            flex-shrink: 0;
            align-self: center;
            color: $primary_color;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;

            > ion-icon {
                position: absolute;
                top: -12px;
                right: -4px;
                color: $primary_color;
                font-size: 24px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    .n7-form-list {
        display: none;
        position: static;
        top: 47px;
        left: 0;
        right: 0;
        border: 1px solid $border_color;
        border-top: none;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $bg_color_light;
        z-index: 1;
    }

    .n7-form-list__options {
        list-style: none;
        max-height: none; // 250px;
        margin: 0;
        padding: 0 16px;
        overflow-y: auto;
    }

    .n7-form-list__prefix,
    .n7-form-list__suffix,
    .n7-form-list__option {
        padding: 8px 16px;
        border: none;
        line-height: 30px;
        outline: none;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .n7-form-list__prefix {
        border-bottom: 1px solid $border_color;
    }

    .n7-form-list__suffix {
        border-top: 1px solid $border_color;
    }

    .n7-form-list__button {
        color: $primary_color;
        font-weight: bold;

        > ion-icon {
            font-size: 24px;
            vertical-align: sub;
        }
    }

    .n7-form-list__option {
        display: flex;
        padding: 8px 0;
        border-top: 1px solid $border_color;
        line-height: 48px;
        outline: none;
        text-align: center;
        align-items: center;

        &:first-child {
            border: none;
        }

        .option-icon {
            flex-basis: 0;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 32px;
        }

        .option-label {
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
            padding: 0 16px;
            font-size: 18px;
            letter-spacing: -0.11px;
            line-height: 21px;
            text-align: left;
        }

        .option-checkbox {
            flex-basis: 0;
            flex-grow: 0;
            flex-shrink: 0;
            color: #979797;
            font-size: 24px;
        }

        ion-icon {
            vertical-align: middle;
        }
    }

    .is-open {
        .n7-form-item__group {
            border-bottom: none;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            z-index: 99;

            // .n7-form-item__value {
            //     color: $primary_text_color;
            // }
       
        }

        .n7-form-list {
            display: block;

            // box-shadow: 0 20px 40px -20px rgba(186,186,186,0.8);
            // z-index: 99;
       
        }

        &.with-backdrop:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba($color: $bg_color_dark, $alpha: 0.3);
            z-index: 98;
            cursor: default;
        }
    }
}

n7-file-upload {
    display: block;

    // margin: 8px 0 0;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 100px;
            flex-basis: 100px;
            flex-grow: 1;
            flex-shrink: 0;
            align-self: center;
            color: $primary_color;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            > ion-icon {
                font-size: 24px;
                vertical-align: sub;
            }
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;

            > ion-icon {
                position: absolute;
                top: -24px;
                right: -18px;
                color: $tertiary_text_color;
                font-size: 48px;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        .n7-form-item__file {
            display: none;
        }
    }

    .has-file {
        .n7-form-item__value {
            color: $tertiary_text_color;
            text-align: left;
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }
}

n7-datetime-picker {
    display: block;

    // margin: 8px 0 0;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item {
        &.disabled {
            pointer-events: none;

            &.editable {
                opacity: 1;
            }
        }
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;
        cursor: pointer;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 100px;
            flex-basis: 100px;
            flex-grow: 1;
            flex-shrink: 0;
            align-self: center;
            color: $primary_color;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            > ion-icon {
                font-size: 24px;
                vertical-align: sub;
            }
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;

            > ion-icon {
                position: absolute;
                top: -12px;
                right: -4px;
                color: $tertiary_text_color;
                font-size: 24px;
                vertical-align: middle;
                cursor: pointer;

                &.large {
                    top: -18px;
                    right: -10px;
                    font-size: 36px;
                }
            }
        }

        .n7-form-item__datetime {
            display: none;
        }
    }

    .has-file {
        .n7-form-item__value {
            color: $tertiary_text_color;
            text-align: left;
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    .editable {
        .n7-form-item__group {
            background-color: $border_color;

            .n7-form-item__value {
                color: $tertiary_text_color;
                text-align: left;
            }
        }

        .n7-form-item__suffix {
            > ion-icon {
                color: $primary_color;
            }
        }
    }
}

n7-photo-loader {
    display: block;
    width: 100%;

    &:first-child {
        margin: 0 0 0;
    }

    &.disabled {
        pointer-events: none;
    }

    .n7-form-item__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 13px;
        grid-row-gap: 14px;

        &.one-column {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .n7-form-item__group {
        position: relative;
        box-sizing: border-box;
        padding: 0;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;
        overflow: hidden;

        .n7-form-item__file {
            display: none;
        }
    }

    .n7-form-item__image-actions {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 8px;
        color: $primary_color;
        z-index: 2;

        .image-action {
            align-self: center;
            box-sizing: border-box;
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            line-height: 1.2;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            ion-icon {
                font-size: 40px;
            }
        }

        .image-action__add,
        .image-action__zoom {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }

        .image-action__upload,
        .image-action__remove {
            flex-grow: 0;
            padding: 0;
            border-radius: 5px;
            line-height: 48px;
            background-color: $tertiary_color;
        }

        .image-action__remove {
            align-self: flex-end;
            max-width: 200px;
            color: $error_color;
        }
    }

    .has-file {
        .n7-form-item__value {
            color: $tertiary_text_color;
            text-align: left;
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    @media (min-width: 576px) {
        .n7-form-item__grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

n7-photo-loader-advanced {
    display: block;
    width: 100%;

    &:first-child {
        margin: 0 0 0;
    }

    &.disabled {
        pointer-events: none;
    }

    .n7-form-item__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px; // 13px;
        grid-row-gap: 8px; // 14px;
        margin-bottom: 24px;

        &.one-column {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .n7-form-item__group {
        position: relative;
        box-sizing: border-box;
        padding: 0;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;
        overflow: hidden;

        .n7-form-item__file {
            display: none;
        }
    }

    .n7-form-item__image-actions {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 8px;
        color: $primary_color;
        z-index: 2;

        .image-action {
            align-self: center;
            box-sizing: border-box;
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            line-height: 1.2;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            ion-icon {
                font-size: 40px;
            }
        }

        .image-action__add,
        .image-action__zoom {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            line-height: 0;

            > ion-icon {
                margin-top: -20px;
                font-size: 60px;
            }
        }

        .image-action__upload,
        .image-action__remove {
            flex-grow: 0;
            flex-basis: 48px;
            flex-shrink: 0;
            padding: 0;
            border-radius: 5px;
            line-height: 48px;
            color: $secondary_text_color;
            background-color: $tertiary_color;

            > ion-icon {
                font-size: 16px;
                vertical-align: middle;
            }
        }

        .image-action__remove {
            align-self: flex-end;
            max-width: 200px;
            color: $error_color;
            background-color: $tertiary_color_80;
        }

        .image-action__draw {
            flex-grow: 0;
            flex-basis: 48px;
            flex-shrink: 0;
            align-self: flex-start;
            max-width: 200px;
            padding: 0;
            border-radius: 5px;
            line-height: 48px;
            color: $secondary_text_color;
            background-color: $tertiary_color;
            background-color: $tertiary_color_80;

            > ion-icon {
                font-size: 16px;
                vertical-align: middle;
            }
        }

        .image-action__tag-counter {
            align-self: flex-end;
            flex-grow: 0;
            flex-shrink: 0;
            max-width: 100%;
            padding: 3px 5px;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;
            text-align: center;
            background-color: var(--ion-color-primary);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .image-action__contextual {
            display: flex;
            align-items: center;
            padding: 0 8px;
            color: #fff;
            font-size: 13px;
            text-align: left;

            // text-shadow: 2px 2px #000000;

            > span {
                flex-grow: 1;
            }
        }
    }

    .has-file {
        .n7-form-item__value {
            color: $tertiary_text_color;
            text-align: left;
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    // @media (min-width: 576px) {
    //     .n7-form-item__grid {
    //         grid-template-columns: repeat(3, 1fr);
    //     }
    // }
}

n7-textarea-suggestions {
    display: block;
    width: 100%;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item {
        cursor: pointer;

        &.disabled {
            opacity: 1;
            cursor: initial;
            pointer-events: none;

            .n7-form-item__group {
                border: 0;

                > textarea {
                    color: #000000;
                    background-color: #F2F3F4;
                }
            }
        }
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0;
        border: 1px solid $border_color;
        border-radius: 10px;
        background-color: $bg_color_light;
        overflow: hidden;

        > .textare-action {
            width: 100%;
            padding: 12px 20px;
            border: none;
            border-bottom: 1px solid $border_color;

            // border-radius: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: var(--ion-color-primary);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 1.2;
            overflow: hidden;
        }

        > textarea {
            width: 100%;
            padding: 16px 16px;
            border: none;
            border-radius: 5px;
            overflow: hidden;
            outline: none;
        }

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            flex-grow: 1;
            align-self: center;
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;

            > ion-icon {
                position: absolute;
                top: -24px;
                right: -18px;
                color: $tertiary_text_color;
                font-size: 48px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    .textare-action {
        ion-icon {
            margin-right: 4px;
            vertical-align: middle;
        }

        p {
            display: inline;
            margin: 0;
            padding: 0;
            vertical-align: middle;
        }
    }
}

n7-data-input {
    display: block;

    &:first-child {
        margin: 0 0 0;
    }

    .n7-form-item__group {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 8px 16px;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $bg_color_light;

        &:focus-within {
            border: 1px solid $primary_color;
        }

        .n7-form-item__value {
            width: 0;
            flex-basis: 0;
            flex-grow: 1;
            align-self: center;
        }

        .n7-form-item__prefix {
            display: block;
            padding-right: 8px;
            margin-right: 8px;
        }

        .n7-form-item__prefix,
        .n7-form-item__suffix {
            position: relative;
            align-self: center;

            > ion-icon {
                position: absolute;
                top: -24px;
                right: -18px;
                color: $tertiary_text_color;
                font-size: 48px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    // .n7-form-item__value[type=number] {
    //     text-align: right;
    // }

    .has-error {
        .n7-form-item__group {
            border: 1px solid $error_color;
        }
    }

    &.large {
        .n7-form-item__group {
            padding: 16px;
            font-size: 22px;

            .n7-form-item__prefix {
                > ion-icon {
                    left: -10px;
                }
            }

            .n7-form-item__suffix {
                > ion-icon {
                    right: -10px;
                }
            }
        }
    }
}

n7-multiple-items {
    border: 2px solid red;
}

.n7-detections-item,
.n7-components-item {
    > div {
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }

        > div {
            margin-top: 8px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
