// ------------------------------------ //
// #SIDEBAR
// ------------------------------------ //

.st-side-content {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 460px;
    max-width: 460px;
    padding-top: 45px;
    background-color: #fff;
    opacity: 1;
    z-index: 3;
    -webkit-transition: margin 0.6s ease-in-out;
    /* Safari prior 6.1 */
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    &.left {
        left: 0;
        transform: translateX(-460px);
        border-right: 2px solid $gray-light;
    }
    /* sidebar size for page management */
    &-management {
        width: 880px;
        max-width: 880px;
        &.left {
            transform: translateX(-880px);
        }
    }
    &.open {
        transform: translateX(0);
        width: calc(100% - 56px);
        &.mobile {
            width: 100%;
            border-left: 0;
            border-right: 0;
        }
    }
    &.other-open {
        opacity: 0;
        visibility: hidden;
    }
    @media (min-width: 768px) {
        &.other-open {
            opacity: 1;
            visibility: visible;
        }
    }
}

.st-side-content__block {
    max-height: 100%;
    padding: 16px 16px 80px;
    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap;
    &.task-detail {
        position: absolute;
        top: 45px;
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: #ffffff;
        z-index: 2;
    }

    @media (min-width: 768px) {
        padding: 16px 32px 80px;

        &.task-detail {
            padding: 0;
        }
    }
}
.st-side-content__nav {
    position: absolute;
    top: 0;
    padding: 0;
    margin-top: 53px;

    .st-nav-item {
        display: inline-block;
        height: 50px;
        margin: 12px 0;
        padding: 12px 20px 12px;
        border: 2px solid $gray-light;
        list-style: none;
        white-space: nowrap;
        color: $color-main;
        font-size: 18px;
        background-color: #fff;
        cursor: pointer;
        ion-icon {
            font-size: x-large;
            vertical-align: middle;
            padding-right: 5px;
        }
        &.is-warning {
            color: $color-danger;
            position: relative;
            .pulse-ring {
                display: block;
                border-color: $color-danger;
                top: 5px;
                left: 12px;
            }
        }
        &.open {
            ion-icon {
                padding-right: 0;
            }
        }
        @media (max-width: 768px) {
            .st-nav-item__name {
                display: none;
            }
        }
    }
    .left & {
        left: 100%;
        .st-nav-item {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            &.open {
                border-left: 1px solid #fff;
            }
        }
    }
    .right & {
        right: 100%;
        .st-nav-item {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            &.open {
                border-right: 1px solid #fff;
            }
        }
    }
}
