// ------------------------------------ //
// #VARIABLES
// ------------------------------------ //

/* Custom variables */
$color-main: #000080;
$color-primary-tint: #1a1a8d;
$color-success: #0b8f1f;
$color-danger: #cc0019;
$color-note: #ffab00;
$color-light-blue: #dce5e8;

$blue100: #bee7ff;

$gray-light: #dbdbdb;
$gray100: #edf2f3;
$gray300: #bfc8cb;
$gray400: #a2abae;
$gray500: #878f92;
$gray600: #6c7477;
$gray700: #535b5d;
$gray800: #242b2d;
$gray900: #0e1618;

$white: #ffffff;
$black: #000000;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$spacing: 10px;
