@use 'sass:math';

// ------------------------------------ //
// #for management pages
// ------------------------------------ //

/* ------------------------------------ *\
#Time reporter: Team summary 
\* ------------------------------------ */
.st-report-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    &__name {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    &__cta {
        position: relative;
    }
}
.st-report-message {
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -16px;
    padding: 15px 32px;
    background-color: #aedeff;
    color: $color-main;
    white-space: pre-wrap;
    &.st-report-message-margin {
        margin-left: -32px;
        margin-right: -32px;
        margin-top: -16px;
    }
    a {
        color: $color-main;
    }
    &.is-warning {
        background-color: $color-danger;
        color: white;
        a {
            color: white;
        }
    }
}
.st-report-cards {
    margin-left: -20px;
    margin-right: -20px;
}
.st-report-card {
    // flex: 1;
    padding: $spacing $spacing * 1.5;
    // margin: $spacing;
    min-height: 80px;
    border: 1px solid #dddddd;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &__detail {
        display: flex;
        align-items: center;
    }
    &__data {
        padding-right:  math.div($spacing, 2);
    }
    &.is-warning {
        color: $color-danger;
        border: 1px solid #e26d7b;
        background-color: #f5e0e3;
        cursor: pointer;
        .st-report-card__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &::after {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                // background-image: url("../assets/icon/Management/chevron-right-regular-red.svg");
            }
        }
        &:hover {
            color: $white;
            border: 1px solid $color-danger;
            background-color: $color-danger;
            .st-report-card__title::after {
                filter: brightness(0) invert(1);
            }
        }
    }
}

/* ------------------------------------ *\
#Time reporter: Generic activities
\* ------------------------------------ */
.st-report-breadcrumbs {
    color: $gray500;
}

.st-select-activities {
    display: flex;
    align-items: center;
    padding: 0;
}
/* page resource details */
.st-resource {
    max-width: 800px;
    margin: 0 auto 20px;
    ion-col {
        padding: 0 10px 20px 10px;
    }
    &__profile {
        padding: 25px;
        text-align: center;
    }
    &__name {
        line-height: 25px;
        padding-bottom: 15px;
    }
    &__company {
        color: $gray500;
        padding-bottom: 15px;
    }
    &__badge {
        margin-top: auto;
        img {
            max-height: 110px;
        }
    }
}
/* resource avatar style */
.st-avatar {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    &--sm {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}
.st-avatar-holder {
    background-color: $gray100;
    color: $gray800;
    font-size: 51px;
    line-height: 89px;
    text-align: center;
    &--sm {
        font-size: 24px;
        line-height: 43px;
    }
}
/* page surface report */
.display-mobile {
    display: none;
    @media all and (max-width: 768px) {
        display: block;
    }
}
.st-report-surface {
    display: grid;
    grid-template-columns: 500px 1fr;
    @media all and (max-width: 768px) {
        grid-template-columns: auto;
    }
    &__filter {
        padding: 30px 40px 100% 40px;
        border-right: 1px solid $color-light-blue;
        @media all and (max-width: 768px) {
            padding: 20px;
        }
        &-btn {
            right: inherit !important;
            width: 500px;
            display: block;
            @media all and (max-width: 768px) {
                position:sticky !important;
                bottom: 20px !important;
                width: 100%;
                text-align: center;
                background-color: #ffffff;
                box-shadow: 0 20px 40px -20px #000000;
                margin: 8px auto;
                border: 1px solid #dddddd;
            }
        }
    }
    &__report {
        padding: 30px 40px;
        @media all and (max-width: 768px) {
            padding: 20px;
        }
        &-btn {
            left: inherit !important;
            width: calc(100% - 500px);
            display: block;
            @media all and (max-width: 768px) {
                //width: 50%;
                display: none;
            }
        }
    }
    .form-item .item-button.primary {
        font-weight: 500;
    }
}

.st-resources--empty {
    text-align: center;
    font-size: 21px;
    line-height: 40px;
    font-weight: bold;
    padding: 40px 20px;
    ion-icon {
        font-size: 60px;
        color: $color-light-blue;
    }
}

.st-import {
    position: relative;
    max-width: 500px;
    min-height: 370px;
    &__title {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        text-align: center;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 21px;
        color: $gray500;
        text-align: center;
    }
    &__link {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        a {
            text-decoration: none;
        }
    }
    &__input {
        max-width: 70%;
        margin: 0 auto;
    }
    &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1000;
    }
    .st-import--loading {
        text-align: center;
        ion-spinner {
            margin: 40px 0 50px;
            transform: scale(2.5);
            color: $color-main;
        }
    }
    .st-import--states {
        text-align: center;
        ion-icon {
            margin-top: 60px;
            font-size: 60px;
        }
        &.is-completed {
            ion-icon {
                color: $color-success;
            }
        }
        &.is-error {
            ion-icon {
                color: $color-danger;
            }
        }
    }
}

/* ------------------------------------ *\
  #CIRCLE-PROGRESS-SVG
\* ------------------------------------ */
.st-circle-progress {
    display: block;
    height: 170px;
    width: 170px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    &__percentage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 170px;
        width: 170px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        font-size: 36px;
        line-height: 40px;
        font-weight: bold;
        .st-circle-progress__percentage-value {
            display: flex;
            align-items: center;
            span {
                padding-left: 5px;
                font-size: 14px;
            }
        }
    }
    &__percentage-desc {
        font-size: 14px;
        line-height: 21px;
        color: white;
        font-weight: normal;
    }
    /* SVG */
    svg {
        height: 170px;
    }
    circle {
        width: 100%;
        height: 100%;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1s linear;
        stroke-width: 50;
    }

    /* Base circle - gray track */
    &__base {
        stroke: $color-light-blue;
    }

    /* Dynamic bar */
    &__dynamic-bar {
        stroke: $color-main;
        /* RED */
        .is-danger & {
            stroke: $color-danger;
        }
    }
    &.is-small {
        height: 60px;
        width: 60px;
        .st-circle-progress__percentage {
            height: 60px;
            width: 60px;
        }
        .st-circle-progress__percentage-value {
            font-size: 14px;
            span {
                padding-left: 2px;
                font-size: 10px;
            }
        }
        svg {
            height: 60px;
        }
        circle {
            stroke-width: 10;
        }
    }
    &.theme-white {
        .st-circle-progress__base {
            stroke: #003fa0;
        }
        .st-circle-progress__dynamic-bar {
            stroke: white;
        }
    }
}
/* ------------------------------------ *\
  #CHART PIE WHIT DISTRIBUTION DATA
\* ------------------------------------ */
.st-card__distribution {
    .st-card__distribution-item {
        .st-dot {
            margin-right:  math.div($spacing, 2);
        }
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
        .st-card__distribution-name {
            display: grid;
            grid-template-columns: 15px 1fr;
            align-items: baseline;
            padding-right: 5px;
        }
        .st-p4 {
            min-width: 43px;
        }
    }
    .st-card__distribution-data {
        display: grid;
        grid-template-columns: 1fr 50px;
        .st-chart-pie {
            position: relative;
            width: 80px;
            height: 40px;
            margin-left: -10px;
            border-radius: 50%;
            &--empty {
                ion-icon {
                    width: 57px;
                    height: 50px;
                    color: $color-light-blue;
                }
            }
            .st-chart-pie__tag {
                position: absolute;
                left: 70%;
                top: 30%;
                padding: 4px;
                font-size: 10px;
                line-height: 12px;
                border-radius: 2px;
                background-color: #393939;
                color: white;
                white-space: nowrap;
            }
        }
        &.theme-lg {
            display: flex;
            align-items: center;
            justify-content: center;
            .st-chart-pie {
                width: 240px;
                height: 120px;
                margin: auto;
                &.st-chart-pie--empty {
                    margin: 20px auto;
                    width: 57px;
                    height: 50px;
                }
            }
        }
    }
}

/* ------------------------------------ *\
  #MANAGEMENT  DATA
\* ------------------------------------ */
.st-card-cost,
.st-card-resources {
    word-break: break-word;
    .st-card-resources__base {
        font-size: 14px;
        line-height: 21px;
    }
    .st-card__update {
        margin: 10px auto 30px;
        text-align: center;
    }
}

.st-card-cost {
    height: 100%;
    padding-top: 30px;
    background-color: $color-main;
    background-image: url("/assets/img/BackgroundX2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    color: white;
}
@media screen and (max-width: 768px) {
    .st-card-cost {
        background-image: none;
    }
}

.st-card-work {
    margin: 10px 15px 15px;
    padding: 10px;
    .st-card-work__title {
        padding: 10px;
    }
    .st-card-work__steps {
        padding-left: 10px;
        padding-right: 10px;
    }
    .st-card-work--empty {
        height: 240px;
        text-align: center;
        ion-icon {
            width: 60px;
            height: 60px;
            padding-top: 18px;
            color: $color-danger;
        }
    }
}

.st-card-info {
    padding: $spacing;
    height: 100%;
    border-radius: $spacing;
    background-color: $gray100;
    &.is-warning {
        background: linear-gradient(128.17deg, #ff8442 0%, #ff6526 100%);
        color: white;
        .st-card-info__detail-addon {
            color: white;
        }
    }
    &.st-card-info__main {
        display: flex;
    }
    .st-card-info__main-index {
        display: inline-flex;
        padding-left: 10px;
    }
    .st-card-info__main-title {
        min-width: 130px;
        margin-right: 10px;
    }
    .st-card-info__main-data {
        display: flex;
        align-items: center;
        font-size: 48px;
        line-height: 40px;
        font-weight: bold;
        word-break: break-all;
        .st-h5 {
            margin-left: 5px;
        }
    }
    .st-card-info__detail-data {
        display: flex;
        align-items: center;
        word-break: break-word;
        font-weight: bold;
        font-size: 21px;
        line-height: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .st-card-info__detail-unit {
        min-width: 40px;
        padding-left: 5px;
        font-size: 12px;
        font-weight: bold;
        line-height: 21px;
    }
    .st-card-info__detail-addon {
        min-width: 50px;
        padding-left: 10px;
        font-size: 10px;
        line-height: 12px;
        color: $gray500;
    }
}
