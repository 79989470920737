// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.
@import "../theme/fonts";
@import "../theme/form";

@import "../theme/n7-form-items";

@import "../theme/custom-variables";
@import "../theme/custom-utilities";
@import "../theme/custom-card";
@import "../theme/custom-form";
@import "../theme/custom-buttons";
@import "../theme/custom-header";
@import "../theme/custom-datatable";
@import "../theme/custom-sidebar";
@import "../theme/custom-management";
@import "../theme/custom-management-steps";
@import "../theme/custom-daterange-picker";

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    line-height: 1.2em;
}

hr {
    height: 0px;
    margin: 0;
    border-width: 0px;
    border-bottom: 1px solid #ebebeb;
}

.title {
    height: 71px;
    color: #000000;
    font-size: 60px;
    font-weight: bold;
}

.subtitle {
    height: 38px;
    color: #000000;
    font-size: 32px;
    font-weight: bold;
}

.page_content {
    height: 68px;
    color: #000000;
    font-size: 16px;
    font-weight: normal;
}

div.box {
    width: 100%;
    border: 2px solid #dbdbdb;
    border-radius: 10px;
    background-color: #fbfbfb;
}

div.box > h3 {
    height: 21px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

div.box > p {
    margin-top: 50px;
    text-align: center;
}

.search {
    margin-top: 3px;
    box-sizing: border-box;
    border: 2px solid #dbdbdb;
    border-radius: 5px;
    height: 38px;
}

.search_icon {
    // margin-left:10px;
}

.profile {
    padding: 10px;
}

.rounded {
    border-radius: 50%;
}

.center {
    text-align: center;
}

.size-30 {
    width: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size-40 {
    width: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size-50 {
    width: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size-60 {
    width: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size-70 {
    width: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size-80 {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size-160 {
    width: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bold_link {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    line-height: 64px;
    cursor: pointer;
}

.hide {
    display: none;
}

$spacing: 10px;

img.icon {
    width: 1em;
}

.boat-card {
    max-width: 900px;
    margin: 0 auto ($spacing * 2);
    padding: $spacing;

    > hr {
        background-color: #ebebeb;
    }

    &.action-top {
        margin: 0 auto;
    }

    &.action-bottom {
        margin: ($spacing * 2) auto ($spacing * 2);
    }

    &.page-top {
        margin: ($spacing * 8) auto 0;
    }

    &.page-full {
        max-width: none;
        height: 100%;
        margin: 0;
        padding: 0;

        &.mobile {
            height: auto;
        }
    }

    &:last-child {
        margin-bottom: $spacing * 8;

        &.page-full {
            margin-bottom: 0;
        }
    }
}

.boat-card__header {
    margin: 0 auto 16px;
    color: #000000;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;

    &.st-boat-card__header {
        margin-bottom: 80px;
        font-size: 60px;
    }
}

.boat-card__content {
    color: #000000;
}

.log-card {
    max-width: 900px;
    margin: 0 auto ($spacing * 2);
    padding: 0 16px;

    > hr {
        background-color: #ebebeb;
    }

    &.action-top {
        margin: 0 auto;
    }

    &.action-bottom {
        margin: ($spacing * 2) auto ($spacing * 2);
    }

    &.page-top {
        margin: ($spacing * 8) auto 0;
    }

    &.page-full {
        max-width: none;
        height: 100%;
        margin: 0;
        padding: 0;

        &.mobile {
            height: auto;
        }
    }

    &:last-child {
        margin-bottom: $spacing * 8;

        &.page-full {
            margin-bottom: 0;
        }
    }
}

.log-card__header {
    margin: 0 auto 16px;
    color: #000000;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
}

.log-card__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #000000;

    .log-card__side-menu {
        display: none;

        @media (min-width: 992px) {
            display: block;
            flex-basis: 180px;

            .section-list {
                position: fixed;
                top: 45px;
                bottom: 0;
                overflow: auto;
                width: 160px;
                margin-right: 10px;
            }
        }
    }

    .log-card__modules {
        flex-grow: 1;

        // flex-basis: 700px;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
    }
}

.log-card__side-menu {
    padding: 0 80px 0 0;

    .section {
        margin: 20px 0 64px;
    }

    .section__title {
        display: block;
        margin: 20px 0 8px;
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
    }

    .section__item {
        display: block;
        margin: 16px 0 8px;
        color: #9b9b9b;
        font-size: 16px;
        line-height: 1.2;
        cursor: pointer;

        &:hover {
            color: #000000;
        }
    }
}

.log-card__modules {
    > hr {
        display: none;
        margin: 40px 0 0;
    }

    .section {
        display: block;
        padding: 0 0 40px;
        border-bottom: 1px solid #ebebeb;

        &.collapsed {
            padding: 0 0 12px;

            .section__items {
                display: none;
            }
        }

        &.disabled {
            pointer-events: none;
        }

        > hr {
            display: none;
            margin: 40px 0 0;
        }

        > n7_button {
            margin: 20px 0 8px;
        }

        &#remark {
            border-bottom: 0;
        }
    }

    .section__title {
        position: relative;
        display: block;
        margin: 20px 0 8px;
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        cursor: pointer;

        > ion-icon {
            position: absolute;
            right: 16px;
            font-size: 24px;
        }
    }

    .section__item {
        display: block;
    }
}

.select {
    cursor: pointer;
    -webkit-touch-callout: none;

    /* iOS Safari */
    -webkit-user-select: none;

    /* Safari */
    -khtml-user-select: none;

    /* Konqueror HTML */
    -moz-user-select: none;

    /* Old versions of Firefox */
    -ms-user-select: none;

    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently */
}

.option {
    cursor: pointer;
    -webkit-touch-callout: none;

    /* iOS Safari */
    -webkit-user-select: none;

    /* Safari */
    -khtml-user-select: none;

    /* Konqueror HTML */
    -moz-user-select: none;

    /* Old versions of Firefox */
    -ms-user-select: none;

    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently */
}

.option:hover {
    color: $color-main;
}

ion-segment.add-point-segment {
    max-width: 400px;
    min-height: 50px;
    margin: auto;

    ion-segment-button {
        margin: 0 3px;
        border: 0;
        border-top: 3px solid $color-main;
        border-radius: 0;
        color: $color-main;

        &.segment-button-disabled {
            opacity: 0.4;
        }

        &.done {
            opacity: 1;
        }
    }
}

ion-popover {
    &.popover-notifications {
        .popover-wrapper {
            .popover-content {
                width: 90vw;
                max-width: 600px;
            }
        }
    }

    &.popover-profile {
        .popover-wrapper {
            .popover-content {
                width: 90vw;
                max-width: 400px;
            }
        }
    }

    &.popover-form {
        --width: auto;
    }
}

ion-modal {
    &.modal-task-status {
        .modal-wrapper {
            // min-height: 320px;
            // max-height: 580px;
            border-radius: 5px;

            // padding: 50px 40px;
            // background: none;
            margin: 16px;
            min-width: 320px;
            max-width: 400px;
        }

        ion-toolbar {
            // margin-top: 8px;
            margin-top: 0px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0px;
            --padding-end: 0px;
            border-top: 1px solid #dddddd;
            height: 56px;

            ion-buttons {
                flex-basis: 50%;
                flex-grow: 1;
                margin: 0;
            }

            .buttons-last-slot {
                ion-button {
                    color: #ffffff;
                    font-weight: bold;
                    background-color: $color-main;
                }
            }

            ion-button {
                flex-grow: 1;
                margin: 0;

                // color: #9B9B9B;
                color: $color-main;
                font-size: 16px;

                // font-weight: bold;
                line-height: 21px;
                text-align: right;
                text-transform: capitalize;
                height: 56px;
                padding: 16px;
                letter-spacing: 0;

                &[disabled] {
                    opacity: 0.4;
                }
            }
        }

        h1 {
            margin: 0 0 16px;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
        }

        p {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            padding: 0 16px;
        }

        .comment {
            margin: 32px auto 0;
        }
    }

    &.modal-hint {
        .modal-wrapper {
            height: 450px;
            border-radius: 10px;

            // padding: 50px 40px;
            background: none;
            margin: 32px;
            max-width: 320px;
        }

        ion-content {
            // --padding-start: 40px;
            // --padding-end: 40px;
            // --padding-top: 50px;
            // --padding-bottom: 50px;
       
        }

        ion-toolbar {
            margin-top: 8px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 8px;
            --padding-end: 8px;
            height: 56px;

            .buttons-last-slot {
                ion-button {
                    color: $color-main;
                }
            }

            ion-button {
                color: $color-main;
                font-size: 16px;
                height: 56px;
                font-weight: bold;
                text-align: center;
                display: block;
                width: 100%;
                letter-spacing: 0;
            }
        }

        h1 {
            margin: 0 0 0.6em;
            color: #000000;
            font-size: 21px;
            font-weight: bold;
            line-height: 53px;
            text-align: center;
        }

        p {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
        }

        img {
            max-width: 100%;
        }
    }

    &.modal-menu {
        .form-modal,
        .modal-wrapper {
            max-width: 360px;
            margin: 16px;
            border-radius: 8px;
        }

        &.bottom {
            align-items: flex-end;
        }

        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                // min-height: 400px;
                max-height: 80vh;
                overflow: auto;
                padding: 0px;
            }
        }

        ion-toolbar {
            margin-top: 8px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 8px;
            --padding-end: 8px;
            height: 56px;

            .buttons-last-slot {
                ion-button {
                    color: $color-main;
                }
            }

            ion-button {
                color: $color-main;
                font-size: 16px;
                height: 56px;
                font-weight: bold;
                text-align: center;
                display: block;
                width: 100%;
                letter-spacing: 0;
            }
        }

        h1 {
            margin: 0 0 0.6em;
            color: #000000;
            font-size: 21px;
            font-weight: bold;
            line-height: 53px;
            text-align: center;
        }

        p {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
        }

        img {
            max-width: 100%;
        }
    }

    &.modal-edit-project {
        .modal-wrapper {
            max-height: 570px; // 490px;
            border-radius: 5px;

            // padding: 50px 40px;
            background: none;
            margin: 16px;
            max-width: 480px;
        }

        ion-toolbar {
            margin-top: 8px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 8px;
            --padding-end: 8px;
            height: 56px;

            .buttons-last-slot {
                ion-button {
                    color: $color-main;
                }
            }

            ion-button {
                color: #9b9b9b;
                font-size: 16px;
                font-weight: bold;
                line-height: 21px;
                text-align: right;
                text-transform: capitalize;
                height: 56px;
                padding: 16px;
                letter-spacing: 0;

                &.button-disabled {
                    opacity: 0.4;
                }
            }
        }

        .boat-modal {
            margin: 0 auto 0;
            padding: ($spacing * 2) $spacing;
        }

        .boat-modal__header {
            margin: 16px auto 32px;
            color: #000000;
            font-size: 32px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
        }

        .boat-modal__content {
            margin: 16px auto 16px;
            color: #000000;
        }

        h1 {
            margin: 0 0 0.6em;
            color: #000000;
            font-size: 21px;
            font-weight: bold;
            line-height: 53px;
            text-align: center;
        }

        p {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            padding: 0 16px;
        }

        .comment {
            margin: 32px auto 0;
        }
    }

    &.modal-form {
        .modal-wrapper {
            max-width: 360px;
            margin: 16px;
            border-radius: 8px;
        }

        &.modal-form-large {
            .modal-wrapper {
                max-width: 50vw;
            }
        }

        &.bottom {
            align-items: flex-end;
        }

        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                // min-height: 400px;
                max-height: 80vh;
                overflow: auto;
                padding: 10px;
            }
        }

        ion-toolbar {
            margin-top: 0;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
            height: 56px;

            ion-button {
                width: 100%;
                margin: 0;
                color: #ffffff;
                font-size: 16px;
                font-weight: bold;
                line-height: 21px;
                text-align: right;
                text-transform: capitalize;
                height: 56px;
                padding: 16px;
                letter-spacing: 0;
                background-color: $color-main;

                &.button-disabled {
                    opacity: 0.4;
                }
            }
        }

        .form-modal {
            margin: 0 auto 0;
            padding: 16px;
        }

        .form-modal__header {
            margin: 0 auto 8px;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.12px;
            line-height: 1.2;
            text-align: center;
        }

        .form-modal__content {
            margin: 20px auto 8px;
            color: #000000;
        }
    }

    &.modal-confirm {
        .modal-wrapper {
            max-height: 200px;
            border-radius: 5px;
            background: none;
            margin: 16px;
            max-width: 360px;
        }

        ion-toolbar {
            // margin-top: 8px;
            margin-top: 0px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0px;
            --padding-end: 0px;
            border-top: 1px solid #dddddd;
            height: 56px;

            ion-buttons {
                flex-basis: 50%;
                flex-grow: 1;
                margin: 0;
                border-right: 1px solid #dddddd;

                &:last-child {
                    border-right: 0;
                }
            }

            .buttons-last-slot {
                ion-button {
                    color: #d0021b;
                }
            }

            ion-button {
                flex-grow: 1;
                margin: 0;
                color: #000000;
                font-size: 16px;
                line-height: 21px;
                text-align: right;
                text-transform: none;
                height: 56px;
                padding: 0;
                letter-spacing: 0;

                &[disabled] {
                    opacity: 0.4;
                }
            }
        }

        .confirm-modal {
            margin: 0 auto 0;
            padding: 16px;
        }

        .confirm-modal__header {
            margin: 0 auto 8px;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.12px;
            line-height: 1.2;
            text-align: center;
        }

        .confirm-modal__content {
            margin: 20px auto 8px;
            color: #000000;
        }
    }

    &.modal-suggestions {
        .modal-wrapper {
            border-radius: 5px;
            margin: 16px;
            min-width: 320px;
            max-width: 400px;
        }

        ion-toolbar {
            margin-top: 0px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0px;
            --padding-end: 0px;
            border-top: 1px solid #dddddd;
            height: 56px;

            ion-buttons {
                flex-basis: 50%;
                flex-grow: 1;
                margin: 0;
            }

            .buttons-last-slot {
                ion-button {
                    color: #ffffff;
                    font-weight: bold;
                    background-color: $color-main;
                }
            }

            ion-button {
                flex-grow: 1;
                margin: 0;
                color: $color-main;
                font-size: 16px;
                line-height: 21px;
                text-align: right;
                text-transform: capitalize;
                height: 56px;
                padding: 16px;
                letter-spacing: 0;

                &[disabled] {
                    opacity: 0.4;
                }
            }
        }

        h1 {
            margin: 0 0 16px;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
        }

        p {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            padding: 0 16px;
        }

        .comment {
            margin: 32px auto 0;
        }
    }

    &.auto-height {
        &.bottom {
            align-items: flex-end;
        }

        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                // min-height: 400px;
                max-height: 80vh;
                overflow: auto;
                padding: 10px;
            }
        }
    }

    &.modal-suggestions.auto-height {
        .ion-page {
            .inner-content {
                padding: 0;
            }
        }
    }

    &.full-height {
        ion-content {
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;
        }
    }
}

.disabled {
    opacity: 0.4;
}

.offline {
    opacity: 0.4;
    cursor: initial;
    pointer-events: none;
}

.modal-fullscreen {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

ion-popover {
    &.modal-form {
        .popover-wrapper {
            max-height: 400px;
            border-radius: 5px;
            background: none;
            margin: 16px;
            max-width: 360px;
        }

        ion-toolbar {
            margin-top: 0;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
            height: 56px;

            ion-button {
                width: 100%;
                margin: 0;
                color: #ffffff;
                font-size: 16px;
                font-weight: bold;
                line-height: 21px;
                text-align: right;
                text-transform: capitalize;
                height: 56px;
                padding: 16px;
                letter-spacing: 0;
                background-color: $color-main;

                &.button-disabled {
                    opacity: 0.4;
                }
            }
        }

        .form-modal {
            margin: 0 auto 0;
            padding: 16px;
        }

        .form-modal__header {
            margin: 0 auto 8px;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.12px;
            line-height: 1.2;
            text-align: center;
        }

        .form-modal__content {
            margin: 20px auto 8px;
            color: #000000;
        }
    }
}

ion-icon {
    &.flip-icon {
        -moz-transform: scaleX(-1); /* Gecko */
        -o-transform: scaleX(-1); /* Opera */
        -webkit-transform: scaleX(-1); /* Webkit */
        transform: scaleX(-1); /* Standard */
        filter: FlipH;
    }
}
