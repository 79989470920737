@use 'sass:math';

.form-item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    // padding: 20px 10px;
    padding: 0;

    &.inline {
        display: inline-block;
        margin: 0 8px 16px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        border-top: 1px solid #ebebeb;
        z-index: 200;

        .item-button {
            border: 0;
            border-radius: 0;
        }
    }

    &.absolute {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        border-top: 1px solid #ebebeb;
        z-index: 200;

        .item-button {
            border: 0;
            border-radius: 0;
        }
    }

    &.segment {
        display: flex;
        flex-direction: row;

        .item-button {
            flex-grow: 1;
            flex-basis: 50%;

            &.size40 {
                flex-basis: 40%;
            }

            &.size60 {
                flex-basis: 60%;
            }
        }
    }

    &.disabled {
        opacity: .4;
        pointer-events: none;
    }

    input {
        border: none;
        line-height: 30px;
        outline: none;

        &:focus {
            outline: none;
        }
    }

    .item-label {
        margin-bottom: 10px;
        padding: 30px 10px 0;
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.1px;
        line-height: 1.2;
    }

    .item-group {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        .item-group__elem {
            flex-basis: 50%;
            flex-grow: 1;
            box-sizing: border-box;
            margin: 0 15px;
            padding: 20px;
            border: 2px solid #DBDBDB;
            border-radius: 5px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.image-preview {
                padding: 0;
                overflow: hidden;
            }
        }

        .item-actions {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &.radio-buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;

            // border: 2px solid #DBDBDB;
            // border-radius: 10px;
            overflow: hidden;

            input[type=radio] {
                display: none;
                margin: 10px;
            }

            input[type=radio] + label {
                flex-grow: 1;
                padding: 8px 16px;
                border: 2px solid #DBDBDB;
                border-left: 1px solid #DBDBDB;
                border-right: 1px solid #DBDBDB;
                color: var(--ion-color-primary);
                line-height: 30px;
                text-align: center;
                text-transform: capitalize;
                background-color: #fff;
                cursor: pointer;

                &:first-of-type {
                    border-left: 2px solid #DBDBDB;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-of-type {
                    border-right: 2px solid #DBDBDB;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            input[type=radio]:checked + label {
                border-color: #BEE7FF;
                font-weight: bold;
                background-image: none;
                background-color: #BEE7FF;
            }
        }

        &.checkbox-buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            overflow: hidden;

            .item-group__elem {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-basis: auto;
                margin: 0 5px;
                padding: 0;
                border: none;
                border-radius: 50px;
                overflow: hidden;
            }

            input[type=checkbox] {
                display: none;
                margin: 10px;
            }

            input[type=checkbox] + label {
                display: block;
                position: relative;
                padding: 10px 15px;
                border: 1px solid #DBDBDB;
                border-radius: 50px;
                color: #9B9B9B;
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 1.2;
                text-align: center;
                text-transform: capitalize;
                background-color: #fff;
                cursor: pointer;
            }

            input[type=checkbox]:checked + label {
                padding-right: 50px;
                border-color: #BEE7FF;
                color: var(--ion-color-primary);
                font-weight: bold;
                background-image: none;
                background-color: #BEE7FF;
            }

            input[type=checkbox] + label > ion-icon {
                position: absolute;
                top: 50%;
                right: 15px;
                margin-top: -12px;
                display: none;
                color: #9B9B9B;
                font-size: 24px;
            }

            input[type=checkbox]:checked + label > ion-icon {
                display: inline-block;
                margin-right: -5px;
                color: var(--ion-color-primary);
            }
        }

        &.checkbox-list {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            overflow: hidden;
            border: 2px solid #DBDBDB;
            border-radius: 5px;
            cursor: pointer;

            .checkbox-list__header {
                position: relative;
                padding: 15px 30px;
            }

            .checkbox-list__label {
                color: var(--ion-color-primary);
            }

            .checkbox-list__label {
                display: block;
                color: var(--ion-color-primary);
                font-size: 17px;
                letter-spacing: -0.17px;
                line-height: 1.2;
                text-align: center;
            }

            .checkbox-list__label + ion-icon {
                position: absolute;
                top: 50%;
                right: 20px;
                margin-top: -12px;
                color: var(--ion-color-primary);
                font-size: 24px;
            }

            .item-group__list {
                padding: 0 15px;
            }

            &.collapsed {
                .item-group__list {
                    display: none;
                }
            }

            .item-group__elem {
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                flex-basis: auto;
                margin: 0;
                padding: 15px;
                border: none;
                border-bottom: 1px solid #DBDBDB;
                border-radius: 0;
                overflow: hidden;

                &:last-child {
                    border-bottom: none;
                }
            }

            input[type=checkbox] {
                display: none;
                margin: 10px;
            }

            ion-icon {
                padding: 0;
                color: #979797;
                font-size: 24px;
            }

            input[type=checkbox] + label {
                flex-grow: 1;
                display: block;
                position: relative;
                padding: 15px 30px;
                color: #000000;
                font-size: 18px;
                letter-spacing: -0.11px;
                line-height: 1.2;
                text-align: left;
                text-transform: capitalize;
                background-color: #fff;
                cursor: pointer;
            }
        }
    }

    input.item-input,
    .item-input-group {
        box-sizing: border-box;
        padding: 8px 16px;
        border: 2px solid #DBDBDB;
        border-radius: 5px;
    }

    &.active {
        input.item-input,
        .item-input-group {
            border-color: var(--ion-color-primary);
        }
    }

    input.item-input {
        padding: 8px 16px;
    }

    .item-input-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 30px;

        input {
            flex-grow: 2;
            flex-shrink: 0;

            // padding: 10px 20px;
            padding: 0;
        }

        .item-input-group__prepend,
        .item-input-group__append {
            flex-grow: 0;
            flex-shrink: 0;

            // padding: 10px 20px;
            padding: 0;
            color: #9B9B9B;
            font-size: 18px;
            letter-spacing: -0.32px;
            line-height: 24px;

            ion-icon {
                font-size: 24px;
                vertical-align: middle;
            }

            &.button {
                cursor: pointer;
                padding: 0px 4px;
                border: 2px solid #dbdbdb;
                box-sizing: border-box;
                border-radius: 5px;
                color: var(--ion-color-primary);

                &:hover,
                &:focus {
                    border-color: #BEE7FF;
                    background-color: #BEE7FF;
                }
            }
        }

        .item-input-group__prepend {
            padding-right: 10px;
            text-align: left;
        }

        .item-input-group__append {
            padding-left: 10px;
            text-align: right;

            &.button {
                padding-top: 0;
                padding-bottom: 0;
            }

            .button {
                margin: 0;
            }
        }
    }

    .item-hint {
        padding: 10px;
        color: #9B9B9B;
    }

    &.autocomplete {
        // width: 100%;

        ion-item {
            ion-input {
                padding: 1px !important;
            }
        }

        ion-list {
            position: absolute;
            width: 95%;
            overflow-y: scroll;
            max-height: 150%;
            border: 5px;
            z-index: 999;
            margin: auto;
            width: auto;
            top: 100%;
            left: 15px;
            right: 15px;
            // margin-top: -30px;
            padding: 0;
            border: 2px solid #DBDBDB;
            border-top: 1px solid #DBDBDB;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: 0 20px 40px -20px rgba(186, 186, 186, 0.8);

            ion-item.item {
                line-height: 60px;

                &:last-child {
                    border-bottom: 0;
                }
            }

            ion-item:hover {
                cursor: pointer;
                background: #dddddd;
            }
        }
    }

    &.item-date {
        cursor: pointer;

        .item-input-group {
            input {
                width: 0;
            }

            &.selected {
                border-color: #BEE7FF;
                color: var(--ion-color-primary);
                background-color: #BEE7FF;

                ion-icon,
                input {
                    color: var(--ion-color-primary);
                    background-color: #BEE7FF;
                }
            }
        }
    }

    .preset-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .preset {
        flex-basis: 30%;
        box-sizing: border-box;
        margin: 1%;

        // border: 1px solid #979797;
        border: none;
        border-radius: 10px;
        background-color: #D8D8D8;
        overflow: hidden;
    }

    .image-item {
        $white: #FFFFFF;
        $grey: #9e9e9e;
        $secured-image-bg: rgba(darken($white, 10), 0.25);
        $spinner-size: 28px;
        $spinner-color: $grey;

        display: block;
        background-color: $secured-image-bg;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding: 0 0 150% 0;

        .spinner {
            position: absolute;
            top: calc(50% - #{math.div($spinner-size, 2)});
            left: calc(50% - #{math.div($spinner-size, 2)});
            width: $spinner-size;
            height: $spinner-size;
            font-size: $spinner-size;
            line-height: $spinner-size;
            color: $spinner-color;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transition: visibility 0s linear, opacity .5s linear;
            opacity: 0;
            visibility: hidden;
            min-width: 100%;
            width: auto;
            max-width: none;
            min-height: 100%;
            transform: translate(-50%, -50%);
        }

        &.img-loaded {
            background-color: transparent;
            border: 0;

            img {
                opacity: 1;
                visibility: visible;
            }

            .spinner {
                display: none;
                visibility: hidden;
            }
        }

        &.hidden {
            display: none;
        }
    }

    // BUTTONS
    .item-button {
        display: flex;
        justify-content: center;
        box-sizing: border-box;

        // width: 100%;
        padding: 8px 16px;
        border: 2px solid transparent;
        border-radius: 5px;
        color: var(--ion-color-primary);
        font-size: 18px;
        letter-spacing: -0.32px;
        text-align: center;
        background-color: transparent;
        cursor: pointer;

        label,
        ion-icon {
            display: inline-block;
            align-self: center;
            vertical-align: middle;
            cursor: pointer;
        }

        label {
            padding: 0 5px;
            line-height: 30px;
            text-align: left;
        }

        ion-icon {
            flex-shrink: 0;
            font-size: 24px;

            &:first-child {
                padding-right: 5px;
            }

            &:last-child {
                padding-left: 5px;
            }
        }

        &.with-description {
            flex-direction: column;
        }

        .button-description {
            color: #000000;
            font-size: 17px;
            letter-spacing: -0.08px;
            line-height: 1.2;
        }

        &.primary {
            border-color: var(--ion-color-primary);
            color: #FFFFFF;
            font-weight: 900;
            background-color: var(--ion-color-primary);

            &.selected,
            &:hover,
            &:focus {
                background-color: darken(#0A6FDB, 10%);
            }
        }

        &.secondary {
            border-color: #DBDBDB;
            color: var(--ion-color-primary);
            font-weight: 500;
            background-color: #fff;

            &.selected,
            &:hover,
            &:focus {
                border-color: transparent;
                color: var(--ion-color-primary);
                background-color: #BEE7FF;
            }
        }

        &.tertiary {
            border-color: #DBDBDB;
            color: #D0021B;
            font-weight: 500;
            background-color: #fff;

            &.selected,
            &:hover,
            &:focus {
                border-color: transparent;
                color: #D0021B;
                background-color: rgb(255, 197, 204);
            }
        }

        &.link {
            border-color: transparent;
            color: var(--ion-color-primary);
            font-weight: 500;
            background-color: #fff;

            &.selected,
            &:hover,
            &:focus {
                border-color: transparent;
                color: var(--ion-color-primary);
                background-color: #BEE7FF;
            }
        }

        &.light {
            border-color: #9B9B9B;
            color: #9B9B9B;
            background-color: #FFFFFF;

            &:hover,
            &:focus,
            &.active {
                border-color: #BEE7FF;
                color: var(--ion-color-primary);
                background-color: #BEE7FF;
            }
        }

        &.secondary.accept {
            color: #0B8F1F;

            &:hover,
            &:focus,
            &.active {
                color: #FFFFFF;
                background-color: #0B8F1F;
            }
        }

        &.secondary.decline {
            color: #D0021B;

            &:hover,
            &:focus,
            &.active {
                color: #FFFFFF;
                background-color: #D0021B;
            }
        }

        &.secondary.progress,
        &.secondary.monitored {
            color: var(--ion-color-primary);

            &:hover,
            &:focus,
            &.active {
                color: #FFFFFF;
                background-color: var(--ion-color-primary);
            }
        }

        &.secondary.remark {
            color: #FFAB00;

            &:hover,
            &:focus,
            &.active {
                color: #FFFFFF;
                background-color: #FFAB00;
            }
        }

        &.light.return {
            border-color: transparent;
            color: #9B9B9B;
            background-color: transparent;

            &:hover,
            &:focus,
            &.active {
                color: var(--ion-color-primary);
                background-color: transparent;
            }
        }

        &.is-disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    .item-file {
        &.hidden {
            display: none;
        }
    }

    .item-date {
        &.hidden {
            display: none;
        }
    }

    .item-textarea {
        > textarea {
            width: 100%;
            padding: 12px 16px;
            border: 1px solid #DBDBDB;
            border-radius: 5px;
            overflow: hidden;
            outline: none;
        }
    }

    //
    &.boat-sections {
        .item-group {
            .item-group__elem.port,
            .item-group__elem.starboard {
                flex-basis: 25%;
                flex-grow: 0;
                flex-shrink: 1;
                overflow: hidden;
            }

            .item-group__elem.decks {
                flex-grow: 1;
                flex-basis: 50%;
            }
        }

        .port,
        .starboard {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item-label {
                margin: 0 auto;
                text-align: center;
            }
        }

        .decks {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            border: 0;

            > .form-item {
                padding-bottom: 0;
            }
        }

        .deck {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 10px 10px;
            border: 2px solid #DBDBDB;
            border-radius: 5px;
            color: var(--ion-color-primary);
            font-weight: 500;
            background-color: #fff;
        }

        .deck-info {
            flex-grow: 1;
            flex-direction: row;
            display: flex;
            align-items: center;

            > .form-item {
                padding: 4px 8px;
            }

            > label {
                color: #DBDBDB;
            }
        }

        .deck-name {
            min-width: 0;
            width: 100%;
            max-width: none;
            color: #000000;
        }

        .deck-file {
            min-width: 0;
            width: 100%;
            max-width: none;

            // flex-grow: 0;
            // flex-shrink: 1;
       
        }

        .deck-remove {
            padding: 8px;

            > .item-button.secondary {
                padding: 8px 4px;
            }
        }

        ion-reorder.md.hydrated {
            padding: 8px;
        }

        .port,
        .starboard {
            > .form-item {
                > label {
                    color: #DBDBDB;
                    text-align: center;
                    white-space: nowrap;
                }
            }
        }
    }

    //
    &.boat-products {
        .products {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            border: 0;

            > .form-item {
                padding-bottom: 0;
            }
        }

        .product {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 10px;
            border: 2px solid #DBDBDB;
            border-radius: 5px;
            color: var(--ion-color-primary);
            font-weight: 500;
            background-color: #fff;
        }

        .product-info {
            flex-grow: 1;
            flex-direction: column;
            display: flex;
            align-items: center;
            padding: 8px;

            > .form-item {
                padding: 4px 8px;
            }

            > label {
                color: #DBDBDB;
            }
        }

        .product-name {
            min-width: 0;
            width: 100%;
            max-width: none;
            color: #000000;
        }

        .product-producer {
            min-width: 0;
            width: 100%;
            max-width: none;
            color: #DBDBDB;
        }

        .product-edit,
        .product-remove {
            padding: 8px;

            > .item-button.secondary {
                padding: 8px 4px;
            }
        }
    }

    &.list-selection {
        > .item-group {
            height: 100%;

            > .item-group__elem {
                padding: 8px;
                overflow: auto;
            }
        }

        ion-list {
            padding: 0;
        }

        ion-item {
            --min-height: 68px;
            --padding-start: 16px;
            --padding-end: 16px;
            cursor: pointer;
        }
    }

    &.active-filters {
        .item-input-group {
            border-color: #BEE7FF;
            color: var(--ion-color-primary);
            background-color: #BEE7FF;
        }

        .filters {
            flex-grow: 1;
            font-size: 12px;
            letter-spacing: -0.06px;
            line-height: 18px;
            white-space: normal;
        }

        label {
            color: #000000;
        }

        ion-icon {
            color: var(--ion-color-primary);
            cursor: pointer;
        }
    }

    &.multiple-uploads {
        .upload_list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 10px 0 0;
            padding: 0;
            border: 0;

            > .form-item {
                padding-bottom: 0;
            }
        }

        .file {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 10px;
            border: 2px solid #DBDBDB;
            border-radius: 5px;
            color: var(--ion-color-primary);
            font-weight: 500;
            background-color: #fff;
        }

        .document-info {
            flex-grow: 1;
            flex-direction: row;
            display: flex;
            align-items: center;

            > .form-item {
                flex-grow: 1;
                padding: 4px 8px;
            }

            > label {
                color: #DBDBDB;
            }
        }

        .document-name {
            flex-basis: 50%;
            flex-grow: 1;
            flex-shrink: 0;
            margin: 14px 0px 14px 20px;
            color: #000000;

            &.disabled {
                opacity: .4;
                pointer-events: none;
            }
        }

        .document-file {
            flex-grow: 0;
            flex-shrink: 1;
        }

        .document-remove {
            padding: 8px;

            > .item-button.secondary {
                padding: 8px 4px;
            }
        }

        ion-reorder.md.hydrated {
            display: none;
            padding: 8px;
        }
    }

    &.multiple-items {
        .item-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 10px 0 0;
            padding: 0;
            border: 0;

            > .form-item {
                padding-bottom: 0;
            }
        }

        .item-elem {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 10px;
            border: 2px solid #DBDBDB;
            border-radius: 5px;
            color: var(--ion-color-primary);
            font-weight: 500;
            background-color: #fff;
        }

        .item-info {
            flex-grow: 1;
            flex-direction: row;
            display: flex;
            align-items: center;

            > .form-item {
                flex-grow: 1;
                padding: 4px 8px;
            }

            > label {
                color: #DBDBDB;
            }
        }

        .item-name {
            flex-basis: 50%;
            flex-grow: 1;
            flex-shrink: 0;
            margin-left: 20px;
            color: #000000;
        }

        .item-file {
            flex-grow: 0;
            flex-shrink: 1;
        }

        .item-remove {
            padding: 8px;

            > .item-button.secondary {
                padding: 8px 4px;
            }
        }

        ion-reorder.md.hydrated {
            display: none;
            padding: 8px;
        }
    }

    &.current-project,
    &.form-item.archived-project {
        margin: 16px 0;
    }
}
